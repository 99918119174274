// Classes for better reading

 $con-tx: #FFFFFF;
 $con-bg: #000000;
 $con-select: #CB0000;

 body.highContrast  {
	background-color: $con-bg !important;
	color: $con-tx !important;
	a {
		border-color: transparent !important;
		box-shadow: none;
		text-decoration: underline !important;
		color: $con-tx !important;
		background-color: transparent !important;
		&.highcontrast-no-underline {
			text-decoration: none !important;
		}
	}
	&.template-portada-view #content-header,
	.bg-white,.bg-category,
	.plone-breadcrumb #breadcrumbs-current,#breadcrumbs-current, // camino de migas
    .documentActions, 
    //guia -marcadoes / paneles
    .panel-body, .panel-heading, .panel, 
	#agenda,#viewlet-below-content-title,
	.bg-black,.text-category,
	.agenda-calendar,
	.agendaGroup,
	#mainMenuBar,
	#content-header,
	.bg-gray-lighter,
	.btn-primary,
	.social-feed-element,
	.bg-gray-darker,
	.social-feed-element .media-body div,
	#social-networks,
	#all-filter-close,#all-filter-close span, .filter-box,// directory
	.description,.event.summary,
	time, .cal_date span, 
	footer ,#breadcrumbs-current,
	.btn-rounded--red, .discreet,
	.pagination, .document-action,
	.pat-autotoc.autotabs, .autotabs,
	.sliderItem__bg,
	.radial-background, .documentActions span,
	.bg-gray-lighter, .bg-text-grey, .bg-header,
	#portalLanguageGoogle .languages select option, // las elecciones del idioma del traductor
	.pagination ul > li.disabled span,
	//rest_rendering#top
	.documentFirstHeading,.summary,[type="submit"],.portletContent,pre,.portletFooter,
	.portalMessage.error,.portalMessage.error,.field.error,.portalMessage.error strong,
	.field.error > label, .field.error div.error, .portalMessage.error > dt ,.optionsToggle label,.bg-header,
	#fondo_menu_slider_avisos,
	#owlSlidesContainer,
	#mainNavWrapper .nav > li > div:hover, #mainNavWrapper .nav > li > div:focus,
	.pestana_texto,  #interes,
	.portalMessage ,
	.portletNavigationTree.portletSiteSetup .inner-configlet:hover,
	input,
	.searchSection label,
	.searchPage .actionMenu dd,
	#mainNavWrapper .mainNav__subList__wrapper a	{
		background: none transparent !important;
		color: $con-tx !important;
		
	}
	.text-white,
	.text-gray-light,
	.text-primary,
	.text-gray,
	.color-primary,
	.text-darker,
	#searchGadget,
	#portal-weather,
	.alcaldeLink__title,
	.standalone, .link-parent, [type="submit"], 
	button,
	#datepicker table thead th,
	#datepicker table,
	.searchPage .documentFirstHeading,
	.sitgesHeader,
	#mainMenu .firstLevel.parent > ul a ,
	.text-color,.text-grey-light,
	.blogUnfolded__title,
	.blogUnfolded__subChilds__link__icon
	{
		color: $con-tx !important; 
	}
	#portal-announcements,
	.pat-autotoc.autotabs, 
	.autotabs,
	.pat-autotoc.autotabs .autotoc-nav, 
	.autotabs .autotoc-nav,
	#mainMenu .firstLevel.parent > ul,
	.homeLinks__deco,
	.bg-primary,
	#pre-header.bg-gray-lighter,
	#content-core .socialShare a,
	#cabecera.affix, #cabecera,
	.blogUnfolded__title,
	.blogUnfolded__subChilds__link::before
	{
		background-color: $con-bg !important;
	}
	.bgHeader,
	.social-feed-element .attachment,
	#interior-slider .interiorSlider__image,
	.interiorSlider__image {
		//display: none !important;
		background-color: $con-bg!important;
		content:url('../img/img_hight_contrast.png')
	}
	.searchGroup,
	input[type="text"], 
	input[type="password"], 
	input[type="email"],
	textarea, 
	select,
	.footerBanners__figure,
	.btn-rounded--red,
	/*.owl-dot.active span,
	.owl-dot span {
		border-color: $con-tx !important;
	}*/
	.pat-livesearch .livesearch-results,
	input[type="text"], 
	input[type="password"],
	input[type="email"],
	textarea, 
	select,
	.standalone, .link-parent, [type="submit"], button,
	#interior-slider,
	.bg-gray-light-medium,
	//.owl-dot span,
	.active .btnEvents, 
	.btnEvents:hover, 
	.btnEvents:focus,
	.btnEvents,
	#portal-searchbox .LSBox {
		background-color: $con-bg !important;
	}
	.btn,
	.btn-primary,
	.homeLinks__icon {
		border: .2rem solid $con-tx;
		background-color: $con-bg;
		color: $con-tx;
	}
	.homeLinks__icon, 
	#portal-weather,
	.footerBanners__figure,
	#portal-logo {
		svg {
			fill: $con-tx !important;
		}
	}
	#content-header #header-logo {
		background-color: $white;
	}
	.social-feed-element .content,
	.footerBanners__link,
	#portal-top > .container {
		background-color: transparent;
	}
	.miniSlider {
		&__text, &__more {
			background-color: $con-bg !important;
			color: $con-tx !important;
		}
	}
	.ambit__bg,
	#links-principal img {
		display: none;
	}
	#viewlet-above-content-blog-alcalde {
		background-image: none !important;
	}
	.image-aemet,
	/*.owl-dot.active span {
		background-color: $con-bg;
		color:$con-tx;
		opacity: 1 !important;
	}*/

	.newsItem , .author-title{
		color:$con-tx;
		background-color: $con-bg;
	}

	.portletContent{
		background-color: $con-bg;
	}
	.navTreeItem{
		background-color: transparent;
		color: $con-tx !important;
		&:hover,&:focus{
			background-color: transparent;
			color:$con-select !important;
		}
	}
	.portletNavigationTree .portletContent > ul > li > a:hover,.portletNavigationTree .portletContent > ul > li > a:focus {
	    background: transparent;
	    color:$con-select !important;
		}
	.portlet .portletContent>ul li:hover ,.portlet .portletContent>ul li:focus{
    	background: transparent !important;
	}
	#portal-searchbox .LSBox {
		border: 1px solid $con-tx !important;
	}
	.sitgesHeader:before {
		background-image: none;
		background-color: $con-tx;
	}
	.footerBanners__title {
		min-height: 100%;
		background-image: none;
		background-color: $con-bg;
		color: $con-tx;
	}
	#homeTramits{
		background-color:$con-bg;
		background:none;
	}
	#newsletter-form{
		background-color:$con-bg;
		background:none;
		color:$con-tx;
	}
	.search-wrapper {
		input[type="text"], 
		input[type="password"], 
		input[type="email"], 
		textarea, 
		select {
		  border: .1rem solid $con-tx;
		}
	}
	.myBtn {
		background-image: linear-gradient(to bottom, $con-bg, $con-bg) no-repeat left bottom/100% 0.4rem transparent;
	}

	::placeholder  {
		color:$con-tx!important;
	}

	.myBtn {
  
        background:transparent!important;
        border: 1px solid $con-tx!important;
     }
    .brand-bg {
        background: url(../../img/logo-blanc-vertical.png) no-repeat center center $con-bg!important;
        border : 1px solid $con-tx!important;
     }
	
	@media screen and (min-width: 75em){
		   #mainMenu .parent > a,
		   #mainMenu .extern {
			   	& > span:after { 
					content: url("data:image/svg+xml;charset=UTF-8, <svg vesion='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.143 5.143'><path fill='rgb(255,255,255)' d='M8.973.17a.549.549 0 0 1 .17.4.549.549 0 0 1-.17.4l-4 4a.549.549 0 0 1-.4.17.549.549 0 0 1-.4-.17l-4-4a.549.549 0 0 1-.17-.4.549.549 0 0 1 .17-.4A.549.549 0 0 1 .571 0h8a.549.549 0 0 1 .402.17z' data-name='Path 412'/></svg>");
				}
		   }
	}

	.documentActions.row{
		padding-left: 20px;
		padding-right: 20px;
	}


   #fondo_menu_slider_avisos{
   	
   	background-image:none;
   }
   .blogUnfolded__subChilds__link::before {
	   background-color: $con-tx;
   }
   .blogUnfolded__subChilds__link:focus, .blogUnfolded__subChilds__link:hover {
	   color: $con-tx !important;
   }

   #minisite-slider{
   	.owl-dots button{
   		background-color: transparent!important;
   	}
   }




	#edit-zone nav>ul a.label-state-published>span:before, 
	#edit-zone nav>ul a.label-state-external>span:before {
	    color: $con-tx!important;
	    border: 1px solid $con-tx!important;

	}

	.main-scope .main-childs-wrapper .scope .childs-wrapper.level-1 // fondo
	{
		background-color: $con-bg!important;
	}
	 .main-scope .main-childs-wrapper .scope .text-wrapper .bar-percent-complete // barra verde
     {
		background-color: $con-bg!important;
	}
	div.childs-wrapper.level-1{
		background-color: $con-bg!important;
	}


.action .text-wrapper {
    border-bottom: 2px solid $con-tx!important;
}

.main-childs-wrapper .scope .text-wrapper .bar-percent-complete{
	height: 6px!important;
}

.main-childs-wrapper .scope .text-wrapper .bar-percent-background {
	height: 13px !important;
	border: 3px solid $con-tx!important;
}


#btn-top{ // el botón de ir arriba, para ver si contorno
	border:1px solid $con-tx!important;
}

// en el buscador las palabras marcadas
.highlightedSearchTerm{
	background:transparent;
	border:1px solid $con-tx!important;
}


//border para los items
.social-feed-container .social-feed-element,.portal-announcements,.footerBanners__item,.footerBanners__title ,.newsItem,.eventItem, input,
.tramits__button,.documentActions,.get-more-news-minisite, .get-more-events-minisite, .get-more-news,.get-more-events-minisite{
		border:1px solid $con-tx!important;
}


/*********************************/

// Gestor del Editor de Contenido

/*************************************/


	table, table span,
	label .required:after, 
    .template-login_form #content-core, body.template-logged_out #content-core,.mce-container-body,
	#login-form, legend,
   .pat-formautofocus, #content-core, // el login 
   .pat-formautofocus .field input, #login-form input, #login-form label,
   .pat-formautofocus .field label,
   .pattern-relateditems-path,// elementos relacionados 
   .portalMessage.info, .portalMessage.info > strong:before, .portalMessage.info > dt:before,
   strong,
   
   /* combos */
   .select2-container, .select-2-container-multi,.select2-search-field, .select2-search-choice, li.select2-search-choice div,
   .select2-search-choice,.select2-container-multi .select2-choices .select2-search-choice,.select2-input,
   #form-group div.select2-container, div.select2-container-multi,

   .label, .label.label-default,th, #btngroup-mainbuttons, #btngroup-mainbuttons .btn,
    ul, ul li, ul.select2-choices, ul .select2-choices, .select2-choices, .select2-chosen,ul li,
   
    #portal-sitemap ul li::before , //puntos delante de los listados  
    .pattern-relateditems-item .pattern-relateditems-item-path,
    
    /*fechas*/
    .picker__day--highlighted, 
    .picker__select--month, .picker__select--year, .picker__box,.picker__day, .picker__day--infocus,.pattern-pickadate-time .picker__input,
    .picker__nav--next,.picker__nav--prev,
    .div.rioccurrences .occurrence.rdate,
    
    .historyRecord,
     
     //ventana modal
     .plone-modal-content,.plone-modal-header,.plone-modal-close,   
     .plone-modal-title,.plone-modal-footer, td.notDraggable,tr.dragable.odd,tr.draggable.odd td,
     .plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close ,
      div.rioccurrences .occurrence.start span.rlabel,
     #popover-workflow,.items.popover-content,.popover-title,.help-block,
     .portlet .portletHeader,

     .state-published,
     input.form-control,
    .pattern-relateditems-result .pattern-relateditems-result-path,
     ul.draggable.odd, ul.draggable.odd li    ,

     .listing > tbody > tr:nth-child(odd) > td, .listing > tbody > tr:nth-child(odd) > th , // usuarios
       button.context, .portalMessage.info > strong, .portalMessage.info > dt,
      .editHook a,.qechild a {   	
    	    background-color: $con-bg !important;
		    color: $con-tx !important;
    }

.alert-warning {
    background-color: $con-bg;
    border-color: $con-tx	;
    color: $con-tx	;
}
    .picker__day--highlighted{text-decoration: underline;}
    
	.picker__nav--next:before  {   
	    border-left: .75em solid $con-tx !important;   
	}
    .picker__nav--prev:before  {
	border-right: .75em solid $con-tx !important; 
    }
	.picker__button--close:before {    
    	color: $con-tx!important;   
	}
	.picker__button--clear:before {
    
    border-top: 3px solid $con-tx!important;   
	}
	.picker__button--today:before {
  
    border-top: .66em solid !important;   
  
	}
	.picker__day--today:before {    
    border-top: .5em solid $con-tx!important;  
   
	}



    // tabla de contenido
    .pat-structure{
        
          .table-bordered>tbody>tr:nth-of-type(odd),//filas pares
          .table-bordered>thead>tr>th, .pat-structure
          .table-bordered>tbody>tr>th, 
          .table-bordered>tfoot>tr>th, .pat-structure 
          .table-bordered>thead>tr>td, 
          .table-bordered>tbody>tr>td,
          .table-bordered>tfoot>tr>td,
          .open>.dropdown-toggle.btn-default,
          .pat-inlinevalidation,
          .btn-default:hover, 
          .table-bordered>tbody>tr>td,
          .dropdown-menu>li>a:hover, .pat-structure .dropdown-menu>li>a:focus,  // menú de opciones
          #btn-selected-items, #btn-selected-items .btn
          th ,//para el titulo de la tabla de contenidos
          #main-menu  // en dispositivos moviles sale fondo del $brand-primary, para que salga el fondo del $con-bg
          {
            background-color: $con-bg !important;
            color: $con-tx !important;
           } 
          .alert.status {   
              color: $con-tx  ;
           }    
    }


  #edit-zone .plone-toolbar-logo:after {  //flecha debajo del logo de plone de la barra del editor
    border-top: 4px solid $con-tx;
 }

  .formControls{ // controles del formulario

  	background-color: $con-bg !important;
  	input{
  		border: 1px solid $con-tx !important; // border de los botones guardar/cancelar
  	}
  }
  #form-widgets-IShortName-id{
  	 background-color: $con-bg !important;
		    color: $con-tx !important;
  }

  .homeLinks__icon:before{// el circulo alrededord de los iconos
          background: linear-gradient(to bottom, $con-bg 0%, $con-tx 100%) !important;
   } 

  input[type="text"], input[type="password"], input[type="email"], button[type="button"],.textarea, select, // campos de formulario
  #portalLanguageGoogle .goog-te-combo,// combo del idioma de google
  .formHelp, // textos ayuda que no sean gris clarito 
  .option .label, // textos de los cuadros de chequeo  
  .glyphicon, span .glyphicon, a span .glyphicon, .btn a .glyphicon  // glyphicon iconos
   {
	     color: $con-tx !important;
	     background-color: $con-bg !important;
  }

  .mce-tinymce.mce-container.mce-panel,.mce-container { //editor de texto
        .mce-menubar .mce-menubtn button span,
        .mce-container-body, //fondo barra herramientas
        button,  // botones barra herramientas
        .mce-icon, i .mce-icon, button i .mce-ico,.mce-i-image:before, i .mce-i-image:before,  //los iconos de la barra de herramientas
        .mce-stack-layout, //como con opciones de la barra de herramientas
        .mce-menu-item,
        .mce-text, .mce-icon, i.mce-icon, button i.mce-ico *:before, 
        .mce-ico.mce-i-link:before, .mce-i-unlink:before,.mce-i-link:before, *:before,
        .mce-menu-item .mce-ico, .mce-menu-item .mce-text , .mce-menu-item, .mce-menu-item:hover, 
        div,ul,li,.mce-i-selected,.mce-menu-item-sep
          {
		    background-color: $con-bg !important;
		    color: $con-tx !important;
		   }
  }

  .glyphycon *:before{
  	color: $con-tx!important;
  }

  .mce-caret {
     border-top: 4px solid $con-tx;    
  }

 // historicos
 #history-list{
    .listing > tbody > tr:nth-child(odd) > td, .listing > tbody > tr:nth-child(odd) > th,
 	.odd,tr, td, hr,.state-private,span, .historic-action, .historyRecord
 	{
			background-color: $con-bg !important;
		    color: $con-tx !important;
 	}

 }
 .diff_add, .diff_sub, .diff_chg{
 	color:$con-bg; 
 }


 #mainMenu .parent > a > span:after { // triangulo debajo de las opciones padre del menú  
    border-color: rgba(666, 666, 666, 1) transparent transparent transparent !important;
    @media screen and (min-width: 75em){
    	border-color: rgba(666, 666, 666, 1) transparent transparent transparent !important;
    }
}
/* SECCIONES PARA RODEARLAS POR UN BORDER Y QUE SE VEAN DIFERENCIALAS*/

  .pagination a, .pagination li a,
  .pat-structure .open>.dropdown-menu,
  .documentActions,
  #popover-workflow,#popover-rename,
  .footerBanners__item,
  .portletContent,
  .row.configlets,
  .autotoc-nav a,
  .portal-announcements,
  .querystring-criteria-index,
  .select2-container,.select2-chosen,
  .autotoc-level-1,
  .liveSearchForm, // buscador de noticias/eventos
  article.calEvent,// los eventos del listado
  .panel-heading.border,  // panel de marcadores
  .filter-box,
  .btn .btn-primary,
  .editHook a,.qechild a,.Edit.Field,  .context [type="submit"],
  .minisite_events .event__item ,
  #interes button, #interes,
  #events div.reloadContent__child,
  #portal-weather-portada,
  .btn-link,
  .portalMessage, 
  .mainNav__subList__wrapper,
  #search-filter-toggle
  {
    border: 1px solid $con-tx!important;
  }

  .footerBanners__item,#cabecera,.portletNavigationTree.portletSiteSetup .inner-configlet:hover  {
  	border: 2px solid $con-tx!important;
  }


  #edit-zone nav>ul ul li:not(.plone-toolbar-submenu-header)>a:hover:before,
  .plone-toolbar-submenu-header {
      color:$con-tx!important;
  }
  #edit-zone nav>ul ul li:not(.plone-toolbar-submenu-header)>a:before {
      content: "•";  
      color: $con-tx!important;     
  }
  .portletNavigationTree .portletContent a::after , .portletNavigationTree.portletSiteSetup a span {  
      color: $con-tx!important;    
  }
  //* color de fondo transparente para que no pise las lineas*/
  .label .required:after .autotoc-level-1, a .select2-chosen,
  .required.horizontal:after{
    background-color: transparent!important;
  }

  .cancellat, .modificat{
           background-color:  $con-tx;
           color:$con-bg;
		}    
  
  button.select-social[type="button"] {
	  background-color: $con-tx;
	  color: $con-bg;
  }


}