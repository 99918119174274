// Classses for headings and text
  .font-text {
    font-family: $font-family-sans-serif;
  }
  .font-heading {
    font-family: $font-family-serif;
  }

  /*.h1-size { font-size: $font-size-h1; }
  .h2-size { font-size: $font-size-h2; }
  .h3-size { font-size: $font-size-h3; }
  .h4-size { font-size: $font-size-h4; }
  .h5-size { font-size: $font-size-h5; }
  .h6-size { font-size: $font-size-h6; }*/
  /* FONT SIZES */
  .h1-size  { 
    font-size:$font-size-h1 - 14px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h1 - 11px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h1 - 7px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h1; }
  }


  .h2-size  { 
    font-size:$font-size-h2 - 6px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h2 - 4px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h2 - 3px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h2; }
   }

   .h3-size  { 
    font-size:$font-size-h3 - 6px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h3 - 3px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h3 - 2px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h3; }
   }

   .h4-size  { 
    font-size:$font-size-h4 - 5px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h4 - 3px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h4 - 2px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h4; }
   }

   .h5-size  { 
    font-size:$font-size-h5 - 4px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h5 - 3px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h5 - 2px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h5; }
   }

   .h6-size  { 
    font-size:$font-size-h6 - 3px;
    @media (min-width: $screen-sm-min) { 
        font-size: ceil($font-size-h6 - 2px);
    }
    @media (min-width: $screen-md-min) {
        font-size: ceil($font-size-h6 - 1px);
    }
    @media (min-width: $screen-lg-min) { // 1200 pixels - 75em
        font-size: $font-size-h6; }
   }



@media (max-width: $screen-sm-min){
   .h1-xs-size{font-size: ceil($font-size-h1 - 14px); }
   .h2-xs-size{font-size: ceil($font-size-h2 - 6px);}
   .h3-xs-size{font-size: ceil($font-size-h3 - 6px);}
   .h4-xs-size{font-size: ceil($font-size-h4 - 6px);}
   .h5-xs-size{font-size: ceil($font-size-h5 - 4px);}
   .h6-xs-size{font-size: ceil($font-size-h6 - 3px);}
}
@media (min-width: $screen-sm-min) { // 768 pixels - 48em
 .h1-sm-size{font-size: ceil($font-size-h1 - 11px); }
 .h2-sm-size{font-size: ceil($font-size-h2 - 4px); }
 .h3-sm-size{font-size: ceil($font-size-h3 - 4px); }
 .h4-sm-size{font-size: ceil($font-size-h4 - 4px); }
 .h5-sm-size{font-size: ceil($font-size-h5 - 3px); }
 .h6-sm-size{font-size: ceil($font-size-h6 - 2px); }

}


@media (min-width: $screen-md-min) { // 992 pixels - 62em
   .h1-md-size{font-size: ceil($font-size-h1 - 7px); }
   .h2-md-size{font-size: ceil($font-size-h2 - 3px) ;}
   .h3-md-size{font-size: ceil($font-size-h3 - 2px) ;}
   .h4-md-size{font-size: ceil($font-size-h4 - 2px) ;}
   .h5-md-size{font-size: ceil($font-size-h5 - 2px) ;}
   .h6-md-size{font-size: ceil($font-size-h6 - 1px) ;}
}

@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
  .h1-lg-size { font-size: $font-size-h1;  }
  .h2-lg-size  { font-size: $font-size-h2; }
  .h3-lg-size  { font-size: $font-size-h3; } 
  .h4-lg-size  { font-size: $font-size-h4; } 
  .h5-lg-size  { font-size: $font-size-h5; }
  .h6-lg-size  { font-size: $font-size-h6; }
 }
  


// Alignment
  @mixin set-alignment ($query:'') {
    .text#{$query}-left           { text-align: left; }
    .text#{$query}-right          { text-align: right; }
    .text#{$query}-center         { text-align: center; }
    .text#{$query}-justify        { text-align: justify; }
    .text#{$query}-nowrap         { white-space: nowrap; }
  }
  // ==== Screen independent  
          @include set-alignment('');
          
  // ==== Extra small screen / phone
      @media screen and (max-width: $screen-xs-max) {
           @include set-alignment('-xs');   
      }
  // ==== Small screen / tablet 
      @media screen and (min-width: $screen-sm-min) {
          @include set-alignment('-sm');
      }
  // ==== Medium screen / desktop
      @media screen and (min-width: $screen-md-min) {
           @include set-alignment('-md'); 
      }
  // ==== Large screen / wide desktop    
       @media screen and (min-width: $screen-lg-min) {
           @include set-alignment('-lg');
      }

// style
    .text-normal         	{ font-style: normal; }    
    .text-italic          { font-style: italic; }
    .text-regular         { font-weight: $font-regular;}
    .text-semi            { font-weight: $font-semi;}
    .text-bold            { font-weight: $font-bold; }

// Contextual colors
  
  @include text-emphasis-variant('.text-white', $white);
  
  @include text-emphasis-variant('.text-darker', $gray-darker);
  
  @include text-emphasis-variant('.text-gray', $gray);
  
  @include text-emphasis-variant('.text-gray-light', $gray-light);
  
  @include text-emphasis-variant('.text-gray-light-medium', $gray-light-medium);
  
  @include bg-variant('.bg-white', $white);
  
  @include bg-variant('.bg-brand-light', $brand-light);
  
  @include bg-variant('.bg-brand-gray', $brand-gray);
  
  @include bg-variant('.bg-brand-dark', $brand-gray);
  
  @include bg-variant('.bg-black', $gray-base);
  
  @include bg-variant('.bg-gray', $gray);
  
  @include bg-variant('.bg-gray-light-medium', $gray-light-medium);
  
  @include bg-variant('.bg-gray-lighter', $gray-lighter);
  
  @include bg-variant('.bg-none', transparent);

// Headings
// -----------
    .bigHead {
        font: $font-bold italic 4.0rem/1.25em $font-family-serif;
        color: $white;
        text-shadow: 0 0 .7rem rgba($gray-base, .75);
        margin: 0 auto 0.25em auto;
        @media screen and (min-width: $screen-sm-min) {
            font-size: 6.0rem;
        }
    }
    .headline {
        a {
            color: inherit;
            &:hover {
                opacity: .8;
            }
        }
    }

// Content
// --------------------
    .documentDescription {
        @extend .h4;
        margin-bottom: 1em;
    }


$ints: (0, 1, 2, 3, 4, 5);
$decis: (25, 50, 75);

@mixin font-sizes {
  @each $int in $ints {
    .fs-#{$int} { font-size: #{$int}em }
    .fsr-#{$int} { font-size: #{$int}rem }
    @each $deci in $decis {
      .fs-#{$int}-#{$deci} { font-size: #{$int + '.' + $deci}em }
      .fsr-#{$int}-#{$deci} { font-size: #{$int + '.' + $deci}rem }
    }
  }
}
@include font-sizes();