// Buttons
// --------------------------------------------------
.standalone, [type="submit"], button { //gray
  @include button-variant($gray-darker, $white, $gray-dark);
}

.context { //blue
  @include button-variant($brand-primary, $white,$gray-dark);
}

.destructive { //red
  @include button-variant($brand-danger, $white,$gray-dark);
}

.link-parent {
  @extend .standalone; margin-bottom: $padding-base-vertical;
  &:before {content: "↩ ";top: 3px;position: relative;}
}

// Social Icons
// ----------------------------------------------------
.rounded-icon {
  transition: color .25s ease, background-color .25s ease;
  
  color: $white;
  background-color: $gray-light-medium;
  font-size: 2.0rem;
  line-height: 1em;
  width: 3.6rem; height: 3.6rem;
  padding: 0.8rem 0;
  text-align: center;
  border-radius: 50%;
  display: block;
  &:hover, &:focus, &:active {
    background-color: $link-color;
    color: $white;
  }
  &--white {
    background-color: $white;
    color: $brand-primary;
  }
  &--black {
    background-color: $gray-darker;
    color: $white;
  }
  &--brand-gray {
    color: $brand-gray !important;
  }
}

// Search Box
// ----------------------------------------------------
.searchBox__button {
  transition: color .25s ease;
  font-size: 2.2rem;
  color: $gray-darker;
  background-color: transparent;
  border: none;
  &:hover, &:active, &:focus {
    color: $brand-primary;
    box-shadow: none;
    background-color: transparent;
    outline: none;
    text-decoration: none;
  }
}

// Image effects
// ----------------------------------------------------
.hover-effect {
  position: relative;
  display: block;
  &:before, &:after {
    pointer-events: none;
    position: absolute;
    transition: opacity .25s ease-in;
    opacity:0;
  }
  &:before {
    content: "";
    z-index: 9;
    position: absolute; left:0; top:0; right:0;bottom:0;
    background-color: rgba($gray-darker, 0.5);
  }
  &:after {
    content: "\e82c";
    font-family: "pictello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    font-size: $font-size-h1;
    color: $white;
    left:0; right:0;
    top:50%; transform: translateY(-50%);
    text-align: center;   
    z-index: 10;   
  }
  &:hover{
    &:after, &:before {
      opacity: 1;
    }
  }
  &--view {
    &:after {
      content: "\e807";
      font-family: "Fontello";
    }
  }
}