//
// Scaffolding
// --------------------------------------------------

  html {
    font-size: 62.5%;
  }

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content

  .hiddenStructure,
  .crud-form .header-select {
    @extend .sr-only;
    left: -10000rem;    
  }

  // TABLES
  #content-core table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5em;
    table {
      background-color: $white;      
    }
    & > thead > tr > th, 
    & > thead > tr > td, 
    & > tbody > tr > th, 
    & > tbody > tr > td, 
    & > tfoot > tr > th, 
    & > tfoot > tr > td {
      padding: 0.8rem;
      line-height: 1.5;
      vertical-align: top;
      border-top: 1px solid #DDD;
    }
    & > caption + thead > tr:first-child > th, 
    & > caption + thead > tr:first-child > td, 
    & > colgroup + thead > tr:first-child > th, 
    & > colgroup + thead > tr:first-child > td, 
    & > thead:first-child > tr:first-child > th, 
    & > thead:first-child > tr:first-child > td {
      border-top: none;
    }
    & > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #DDD;        
    }      
  }