/*** _custom.css -> Pineda de Mar

1 - BODY
2 - HEADER
    2.0 - Header top
    2.1 - Main
    2.2 - Portal Logo
    2.3 - Header image
    2.5 - AEMET
    2.6 - Language selector
    2.7 - Social Icons
    2.8 - Search
    		2.8.1 - Advanced Search

3 - PORTADA
  3.1 - Slide
  3.2 - 4 Links
  3.3 - Announcemes / Avisos
  3.4 - Interes
  3.5 - Destacatas
  3.6 - News
  3.7 - Eventos
  3.8 - Tramits
  3.9 - El Temp
  3.10 - XARXES SOCIALS
  3.11 - Bulletins

4 - FOOTER
5 - SEARCH VIEWS (NEWS / EVENTS)
6 - GENERAL
  6.1 - SOCIAL - ICON
  6.2 - COLORES
  6.3 - MARGINS AND PADDING
  6.4 - SIDEBAR / PORTLET NAVEGACIÓN
  6.5 - Breadcrumbs
  6.6 - DISPLAY FLEX
  6.7 - PÁGINA
  6.8 - PAGINACIÓN
  6.9 - SITEMAP
  6.10 - SCROLLUP
  6.11 - PLANO

//7 - MINISITES

8 - EDITOR

9 - DIRECTORY

10 - VIEWS
 10.1 - Unfolded
 10.2 - Search news
 10.3 - Search events
 10.4 - template-accordion_view



 

/*******************/
//1 - BODY

#content-core #parent-fieldname-text table{border:none}
#content-core #parent-fieldname-text table>tbody>tr>td,
#content-core #parent-fieldname-text table>tbody>tr>th,
#content-core #parent-fieldname-text table>tfoot>tr>td,
#content-core #parent-fieldname-text table>tfoot>tr>th,
#content-core #parent-fieldname-text table>thead>tr>td,
#content-core #parent-fieldname-text table>thead>tr>th{border:none}


h1.documentFirstHeading {
	font-size: 36px;
	font-weight: bold;
	color: $brand-text;
	margin-top: 0px;
	/* para q esté a la altura del portlet navegación izquierdo mnp 2019_01_11*/

}

article#content {
	margin-bottom: 0px;
}

.bg-transparent {
	background-color: transparent;
}

.bg-primary {
	background-color: $brand-primary;
}

.bg-grey-dark {
	background-color: $gray-dark;
}

.text-color {
	color: $brand-text;
}

.text-grey-light {
	color: $gray-light;
}

.text-category {
	color: $brand-category;
}

.bg-category {
	background-color: $brand-category;
}

.bg-text-grey {
	color: $brand-text-grey;
}

.text-underline {
	text-decoration: underline;
}

.text-regular {
	font-weight: $font-regular;
}

.text-semi {
	font-weight: $font-semi;
}


.border-white {
	border: 1px solid $white;
	border-radius: 4px;
}

.border-white2 {
	border: 2px solid $white;
	border-radius: 4px;
}

.bottom-line-white {
	border-bottom: 1px solid $white;
}

.bottom-line-primary {
	border-bottom: 1px solid $gray-light-medium;
}

.top-line-white {
	border-top: 1px solid $white;
}

.border-right1 {
	border-right: 1px solid $white;
}

.border-left1 {
	border-left: 1px solid $white;
}

.line-height-2 {
	line-height: 2;
}

.image-preview-width {
	width: 100%;

	@media (min-width: $screen-sm-min) {
		width: 189px !important;
	}
}

.h14-size {
	font-size: 14px;
}

.h16-size {
	font-size: 16px;
}

.h18-size {
	font-size: 18px;
}

.h20-size {
	font-size: 20px;
}

.h22-size {
	font-size: 22px;
}

.h24-size {
	font-size: 24px;
}

.h30-size {
	font-size: 30px;
}

.h36-size {
	font-size: 36px;
}

.h40-size {
	font-size: 40px;
}

.h50-size {
	font-size: 50px;
}

.fontPoppins {
	font-family: $font-family-serif;
}

.btn-link:hover,
.btn-link:focus {
	color: $brand-primary !important;

}

a.text-white:hover,
a.text-white:focus {
	color: $white;
	text-decoration: underline;
}


.rounded-icon:hover,
.rounded-icon:focus,
.rounded-icon:active {
	background-color: $brand-dark;

}

a:hover,
a:focus {
	color: $brand-dark;
	text-decoration: none;
}

.objectFit {
	object-fit: cover;
	width: 100%;
	height: 100%;
	max-width: none;
}

.context {
	color: $brand-primary;
}

li.navTreeTopNode {
	background-color: #007cb2;
}

.navTreeTopNode a {
	color: white !important;
	font-weight: bold;
}

input[type="submit"],
#search-filter-toggle {
	border-radius: 4px;
	border: 1px solid $brand-dark;
	color: $white;
	background-color: $brand-primary;

	&:hover,
	&:focus {
		color: $text-color;
		background-color: $gray-lighter;
	}
}

#search-btn {
	border: 1px solid $brand-border;
}

.myBtn {
	border-radius: 4px;

	&:hover,
	&:focus {
		border: 2px solid $brand-dark !important;
		background-color: $brand-primary;
		color: $white;
		text-decoration: none !important;
	}
}


html, body.frontend { height: 100%;}
body.frontend {display: flex;flex-direction: column;}
#fullMainContainer {flex: 1 0 auto;}


// 2 - HEADER

#interior-slider .sectionHeader {
	position: relative;
}


body.template-portada-view #pre-header #actions-home a,
body.template-contact-info #pre-header #actions-contact a { color: $brand-plage-orange; border-bottom: 1px solid $brand-plage-orange;}

.template-portada-view #cabecera,
.template-minisite-view #cabecera,
.template-logged_out #cabecera {
	position: relative;

}

#cabecera {
	position: relative;

	@media (min-width: $screen-md-min) {
		// 992 pixels - 62em
	}
}

.affixHack {
	display: none;
	height: 8.6rem;

	@media (min-width: $screen-sm-min) {
		// 768 pixels - 48em
		height: 118px;
	}

	@media (min-width: $screen-md-min) {
		// 992 pixels - 62em
		height: 0;
	}


}

#cabecera.affix {
	position: fixed;
	background-color: $brand-primary;
	border-bottom: 1px solid $brand-dark;

	#mainNavWrapper {
		margin-top: 0 !important;
		@media (min-width: $screen-md-min) { // 992 pixels - 62em
			padding-top: 1.8rem;
		}
	}

	#portal-searchbox {
		padding-bottom: 0;
	}

	&+.affixHack {
		display: block;
	}

}

picture.interiorSlider__image {
	opacity: 0.6;
}

//2.1 - Main Navigation

#mainNavWrapper {
	color: $white;

	a {
		color: $white;
		//transition: background-color 0.35s ease-out, color 0.35s ease-out;
		transition: color $transition-fast ease-in;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	button {
		float: left;
		&.text-white {
			&:focus, &:hover {
				color:$gray-light-medium;
			}
		}
	}

	button:hover {
		background-color: transparent;
	}

	.nav>li>div:hover,
	.nav>li {
		&>div:focus {
			color: $white;
		}

		&.active>div {
			border-bottom: 5px solid $white;
		}
	}

	.mainNav__subList__wrapper {
		position: absolute;
		left: -1000em;
		opacity: .33;
		transition: opacity 0.5s linear;

		a {
			color: $brand-dark !important;

			&::after {
				content: '';
				display: block;
				height: .4rem;
				background-color: $brand-primary;
				bottom: 0;
				left: 0;
				right: 0;
				transform: scale(0, 1);
				transform-origin: 0 0;
				transition: transform $transition-fast ease-out;
			}

			&:hover,
			&:focus {
				text-decoration: underline;

				&::after {
					transform: scale(1, 1);
				}

			}
		}
	}

	.nav>li>div:hover .mainNav__subList__wrapper,
	.nav>li>div:focus .mainNav__subList__wrapper {
		opacity: 0.9;
		left: 0;
		position: absolute;
		color: $brand-primary !important;
		background-color: $white; // $brand-primary;
		//border: 1px solid $brand-dark;
		-webkit-box-shadow: 7px 10px 19px -6px rgba(0, 124, 178, 1);
		-moz-box-shadow: 7px 10px 19px -6px rgba(0, 124, 178, 1);
		box-shadow: 7px 10px 19px -6px rgba(0, 124, 178, 1);
		border-radius: 4px;
		display: block;
		width: 22rem;

		a:hover,
		a:focus {
			text-decoration: underline;
			text-decoration-color: $white;
		}


	}

}


//2.2 LOGO
.portalLogo__image {
	max-width: 25.0rem;
	.affix & {
		max-width: 10.0rem;
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		.affix & {
			max-width: 15.0rem;
			margin-bottom: .8rem;
		}
	}
}


// 2.5 - AEMET
#portal-weather {
	font-weight: 300;
	font-size: 18px;

	img {
		height: 32px;
		width: 32px;
	}
}

.image-aemet {
	height: auto;
}

.soletes {
	display: flex;
	flex-wrap: wrap;
	@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
		max-width: 60.0rem;
	}
}
.solete-1 {
	max-width: 11.8rem;
	height: auto;
}
.solete-2 {
	max-width: 6.0rem;height: auto;
}



// 2.6 - Language Translate
#google_translate_element {
	.goog-te-gadget {
		font-family: $font-family-sans-serif;
	}

	.goog-te-gadget-simple {
		border: none;
		background-color: transparent;
		font-size: $font-size-h6;
	}

	.goog-te-gadget-icon {
		position: absolute;
		left: -1000rem;
	}

	.goog-te-menu-value {
		text-transform: none;

		img,
		*[aria-hidden="true"] {
			display: none;
		}

		span {
			border: none !important;
		}

		&:after {
			content: '\e83a';
			font-family: "pictello";
			display: inline-block;
			font-size: 1.6rem;
			margin-left: .5rem;
		}
	}
}

#pre-header a {
	color: #FFFFFF;
}

//   2.7 - Social Icons

#content-header {
	.rounded-icon {
		background-color: transparent;
		font-size: 16px;
		padding: 0px;
		padding-left: 2px;

	}
}


#fondo-cabecera #portal-social-links ul li a {
	padding-top: 1rem;
}



#portal-social-links,
#portal-social-links-footer,
#document-actions-border div.col-md-8 {

	a:hover span[aria-hidden="true"]:before,
	a:focus span[aria-hidden="true"]:before {
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
		transition: all 0.50s ease-in;
	}

	a:hover {
		background-color: $brand-primary;
	}

}

// 2.8  SEARCH


#collapseSearchBox {
	font-size: $font-size-base;

	&.collapse.in {
		position: fixed;
		left: 50%;top: 20.0rem;
		transform: translateX(-50%);
		width: calc(100% - 30px);
		max-width: 70.0rem;
		box-shadow: 3px 3px 5px rgba($gray-base, .50);

		div.LSBox {

			#portal-advanced-search {
				text-decoration: underline;

				a:focus,
				a:hover {
					text-decoration: underline;
				}
			}
		}
	}
}



.searchPage {
	.input-group {
		margin-bottom: 50px;
	}

	.input-group-btn input {
		font-family: Poppins;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 17px;
		border: none;
		border-radius: 0;
	}
}


.searchBox__button,
.icon-search {
	color: $white !important;

	&:hover,
	&:focus {
		content: $brand-dark;
	}

	@media (min-width: $screen-md-min) {
		// 992 pixels - 62em
		margin-top: -7px;
	}

	@media (min-width: $screen-lg-min) {
		// 1200 pixels - 75em
		margin-top: -4px;
	}
}

.showSearchBox:active,
.showSearchBox:focus {
	background-color: transparent;
}

#portal-searchbox {
	background-color: transparent;
	z-index: $zindex-flat + 2;
	right: 0;
	bottom: 0;
	left: 0;
	margin-top: auto;

	.LSBox {
		align-items: center;
		background-color: $brand-primary;
		color: $white;
		padding: 3.5rem 1.5rem 1.5rem 1.5rem;
	}

	input[type="submit"] {
		font: $font-bold 1.6rem/1.5 $headings-font-family;
		color: $white;
		background-color: $gray-base;
		text-transform: uppercase;
		text-align: center;
		padding: 1.0rem;
		border: none;
		border-radius: 0;
		width: auto;
		display: none;
	}

	input[type="text"] {
		border: none;
		padding: 1.0rem;
		height: auto;
	}

	.pat-livesearch .livesearch-results {
		max-width: calc(100% - 5.0rem);
		padding: 0 1.5rem !important;
		height: auto;
	}

	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		input[type="submit"] {
			display: block;
			position: static;
			left: auto;
		}
	}

	@media (min-width: $screen-lg-min) {
		padding-bottom: 3.0rem;
	}

}

.searchBox {
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		float: none;
		z-index: 1000;
		font-size: 1.8rem;
	}
}

#collapseSearchBox {

	.pat-livesearch .livesearch-results .results-summary,
	.livesearch-results,
	.search-result.published,
	li,
	.description,
	a {
		color: $text-color;

		a:hover,
		a:focus {
			text-decoration: underline;
		}
	}

	.pat-livesearch .livesearch-results li {
		margin-bottom: .5em;
		font-family: $font-family-base;

		h4 {
			font-weight: $font-bold;
		}
	}
}

.livesearch-results {
	color: $text-color;
}


//2.8.1 - Advanced Search
dt.actionMenuHeader {
	text-align: right;
}

#searchform .input-group input.searchPage.btn {
	width: 80px;

	@media (min-width: $screen-sm-min) {
		width: 120px;
	}
}

/**************************************
// 3 - PORTADA
****************************************/



// 3.1 Slider

.portalSlider {
	&__text {
		background-color: rgba(0, 0, 0, 0.7);
		height: 97px;

	}

	&__title {

		font-weight: normal;
		font-size: 24px;

	}

	&__description {

		font-family: "Open Sans";
		font-weight: 300;
		font-size: 18px;
		line-height: 1.5;
	}

	&__more {

		&:focus,
		&:hover {
			background-color: $brand-primary;
			text-decoration: none !important;
		}
	}

}

#owlSlidesContainer {
	background: #007cb2;
	opacity: 0.8;
	margin-bottom: 3rem;


	@media (min-width: $screen-sm-min) {
		height: 96px;
		position: absolute;
		bottom: 1px;
		z-index: 800;
		width: 16.3%;
		margin-bottom: 0;
	}


	button {
		background-color: transparent;
		padding-top: 0;
		padding-left: 0;

		&:hover,
		&:focus {
			outline: 0;
			color: $gray-dark;
		}
	}

	.owl-prev span,
	.owl-next span {
		padding-top: 2px;
		font-size: 33px;
		color: $white;
		background-color: transparent;

		&:hover,
		&:focus {
			color: $gray-dark;
		}


	}

	@media (min-width: $screen-sm-min) {

		// 768 pixels - 48em
		button {
			margin-top: 1.5rem;
		}
	}

	@media (min-width: $screen-md-min) {

		// 992 pixels - 62em
		button {
			margin-top: 1.8rem;
			margin-right: 0;
		}
	}
}



#portal-slider .owl-carousel .owl-item img {
	/* tamaño imagen slider*/
	height: 200px;

	@media (min-width: $screen-sm-min) {
		height: 340px;
	}
}

// 3.2 - 4links
#links-principal {
	.linksPrincipal__item {
		@media (min-width: $screen-sm-min) {

			// 768 pixels - 48em
			&:nth-child(odd) {
				padding-right: .2rem;
			}

			&:nth-child(even) {
				padding-left: .2rem;
			}
		}
	}

	img, .linksPrincipal__link {
		height: 216px;
		width: 100%;
		transition: all 0.35s ease-in;

		&:hover {
			filter: brightness(50%);

		}

	}

	span {
		position: absolute;
		top: 0;
		left: 0;
		color: $white;
		padding-left: 3rem;
		padding-top: 0.8rem;
		font-family: Poppins;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
		text-align: left;

		@media (min-width: $screen-sm-min) {
			padding-left: 0.8rem;
		}

		@media (min-width: $screen-md-min) {
			// 992 pixels - 62em
			font-size: 18px;
		}

		@media (min-width: $screen-lg-min) {
			// 1200 pixels - 75em
			font-size: 24px;
		}
	}


}



// 3.3 - Avisos // Anouncemenest
.announcement {

	/* la fecha azulita*/
	p.text-gray-light {
		color: $brand-primary;
	}
}

#portal-announcements {
	font-family: Poppins;

	background-color: transparent;

	.homeAnnouncements__mainRow {
		min-height: 4rem;
		border-bottom: 1px solid $white;

	}

	.bx-cursor {
		display: none;
	}

	p.anouncement-item__title a {
		font-weight: lighter;

	}

	.avisos-header {
		font-weight: normal;
		text-align: left;

		@media (min-width: $screen-sm-min) {
			text-align: right;
			border-right: 2px solid $white;
		}


	}

	button.close {
		position: absolute;
		right: -83px;
		font-weight: lighter;
	}

}



// 3.4 Interes

#interes {
	div.row div.col-md-4 {

		&:focus,
		&:hover {
			border: none;
			outline: 0px;
		}
	}

	a {
		color: darken($brand-primary, 5%);

	}


	.interes__button {
		display: flex;
		justify-content: center;
		align-items: center;

		background-color: $brand-interes2;
		transition: background-color 0.35s ease-out, color 0.35s ease-out;
		width: 100%;
		height: 91px;
		color: $white;
		font-family: Poppins;
		font-weight: bold;
		font-size: 24px;
		text-align: left;
		padding-left: 2px;
		padding-right: 2px;
		line-height: 1.1;

		&:focus,
		&:hover,
		&.active {
			outline: 0;
			border: none;

		}

		svg {
			width: 52px;
			height: 52px;
			fill: $white;
			top: 5px;

		}

		/*span{
			position: absolute;
			top:25px;
		}*/

		&.active {
			background-color: $brand-interes;
			color: $brand-interes2;

			&:focus,
			&:hover {
				outline: 0;
			}

			svg {
				fill: $brand-primary;
			}
		}

	}


	/* cambia el icono de las pestañas de interes dependiendo si están activos o no*/




	#buscats-content {


		div.progress {

			border-radius: 0px;
			padding-bottom: 1px;


		}

		/* barra de progreso del más buscados*/
		.progress {
			height: 23px;
			margin-bottom: 1px;
			margin-top: 1px;
			background-color: transparent;
			padding-top: 1px;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;

		}

		.progress-bar {
			font-size: 10px;
			line-height: 16px;
			padding-top:1px;
			border-radius: 4px;

			text-align:left;
			padding-left: 5px;

			@media (min-width: $screen-sm-min) {
				font-size: 12px;
			}
		}
	}
}
.listFrontpage {
	column-count: 3;
    column-rule: 1px solid #ccc;
    column-gap: 70px;
    column-width: 200px;

	li {
		margin-bottom: 0 !important;
		padding-bottom: .5em; 
		display: flex;
		&:before {
			content: '\e80b';
			font-family: 'pictello';
			color: $text-color;
			display: inline-block;
			margin-right: .50em;
		}
	}
}

.pestana_texto {
	background-color: $brand-interes;

}

#equipaments-button {
	border-radius: $brand-interes2;

}

//3.5 - destacats

.footerBanners__image {
	height: 97px;
}


//3.6 - News / Noticias

#clear-filters {

	background-color: transparent;
	color: $brand-danger;

	&:disabled {
		opacity: .33;
		&:hover, &:focus {
			background-color: transparent;
			color: $brand-danger;
		}
	}
	&:hover,
	&:focus {
		background-color: $brand-danger;
		border: none;
		color: $white;
	}
}

#leadImage {
	img {
		object-fit: cover;
		width: 100%;
	}
}

#firstHeadingNews {
	font-weight: $font-regular;

}

.news-wrapper {
	a {
		color: $brand-text;
	}
}

.home-block .get-more-news {
	left: 50%;
}

.home-block .get-more-news,
.home-block .get-more-events {
	font-family: Poppins;
	font-weight: bold;
	text-align: center;
	border: 2px solid $gray-dark;
	border-radius: 1rem;
	margin-bottom: 1rem;
	z-index: $zindex-flat;
	transition: background-color 0.35s ease-out, color 0.35s ease-out;

	&:hover,
	&:focus {

		border: 2px solid $brand-dark;
		background-color: $brand-primary;
		color: $white !important;
		text-decoration: none !important;

	}


}

.home-block .get-more-news {
	@media (min-width: $screen-sm-min) {
		// 992 pixels - 62em
		top: 0;
		bottom: auto;
		left: 62%;
		margin-top: 3.6rem;
	}

	@media (min-width: $screen-lg-min) {
		// 992 pixels - 62em
		left: 78%
	}
}



.newsItem__image__interior,
.events-item-image__interior {
	width: 100%;
}

.noticia-principal-height,
.evento-principal-height {
	&, &.defaultImage {
		height: 181px;

		@media (min-width: $screen-sm-min) {
			height: 356px;
		}
	}
}
.ieCover.newsItem__figure--featured {
	height: 181px;

		@media (min-width: $screen-sm-min) {
			height: 356px;
		}
}


#news {
	@media (min-width: $screen-sm-min) {
		// 992 pixels - 62em
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	.newsItem {

		a {
			/* portada-noticias, hover de cursor */
			transition: color .35s ease, background-color .35s ease;

			&:hover,
			&:focus {
				opacity: 0.9;
				background-color: $brand-primary;
				text-decoration: none !important;

				a,
				p,
				span,
				h3 {
					color: $white !important;
				}
			}
		}
	}
}

#liveSearchForm input[type="text"] {
	font-size: 18px;
}


.news-wrapper a.newsItem figure {

	max-height: 215px;
	width: 100%;

	@media (min-width: $screen-sm-min) {
		width: 269px;
	}
}

/***********************************/
//3.7 - Events / Eventos / Agenda
/*************************************/

.home-block .get-more-events {
	@media (min-width: $screen-sm-min) {
		// 992 pixels - 62em
		top: 0;
		bottom: auto;
		left: 65%;
		margin-top: 3.6rem;

	}

	@media (min-width: $screen-lg-min) {
		// 992 pixels - 62em
		left: 80%;
	}

}
.defaultImage.evento-principal-height {
	width: auto !important;
}

.occurrence.rdate span.rlabel {
	color: $brand-dark;
	margin: 0 5px;
	font-size: 90%;

}
.selectWrapper {
	width: 100%;
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		width: auto;
	}
}

#location-map {
	margin-top: 3rem;
}

.event.details {
	a {
		color: $brand-primary;

	}
}

div.rioccurrences div.batching {
	font-size: 90%;
}

.calEvent__date {
	border-radius: 4px;
	time {
		display: block;
		line-height: 1;
	}
	abbr {
		border-bottom: none;
		text-decoration: none;
	}
}

.get-more-events {
	left: 1;
	bottom: -10px;

	@media (min-width: $screen-sm-min) {
		bottom: unset;
		left: 77%;
	}
}

.evento-principal-height {
	height: 100%;

	@media (min-width: $screen-sm-min) {
		height: 356px;
	}
}

// .evento-height{
// 	height: 181px;

// }
// .evento-width{
// 	width: 	100%;
// 	max-width: 100%;
// 	 @media (min-width: $screen-sm-min) {
// 	width: 189px;
// 		}
// }



#events {

	@media (min-width: $screen-sm-min) {
		// 992 pixels - 62em
		padding-left: 1.6rem;
		padding-right: 1.6rem;

	}

	.eventItem {
		div {
			padding-left: 0px;
			padding-right: 0px;

			@media (min-width: $screen-sm-min) {
				// 992 pixels - 62em
				padding-left: 15px;
				padding-right: 15px;
			}



		}
	}
}

.divEventItem {
	transition: background-color 0.35s ease-out, color 0.35s ease-out;

	a {

		&:focus,
		&:hover {
			background-color: $brand-primary;
			text-decoration: none !important;

			a,
			p,
			span {
				color: $white;
				text-decoration: none;
			}
		}
	}
}

.fecha-superpuesta {
	position: absolute;
	top: -23px;
	margin-left: 15px;
	width: 5rem;
	margin-top: 2.4rem;
	background-color: rgba($gray-base, .65);
}

.eventsItem__date {
	width: 7.2rem !important;
	font-size: 20px;

	abbr {
		border: none;
		text-decoration: none;
	}
}

#search-category {

	@media (min-width: $screen-sm-min) {
		min-width: 30rem;

	}
}

.eventsHeader {
	font-weight: $font-regular !important;
}

div.event.details figcaption {
	margin-left: 0.8rem;
}


//3.7  TRAMITS

//3.8 EL TEMPS


.plage-icon {
	transition: color .25s ease, background-color .25s ease;
	font-size: 2.0rem;
	line-height: 1em;
	width: 59px;
	height: 59px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	text-align: center;
	border-radius: 50%;
	display: block;

	&__verda {
		background-color: $brand-plage-green;
	}

	&__taronja {
		background-color: $brand-plage-orange;
	}

	&__vermella {
		background-color: $brand-plage-red;
	}

	&__blava {
		background-color: $brand-plage-blue;
	}
}

.plage__text {
	font-family: $font-family-serif;
	padding-left: 15px;
	padding-top: 1.1rem;
	font-size: 16px;
	font-weight: $font-light;
}

//3.10 XARXES SOCIALES


.social-feed-element {
	width: 100%;
	background-color: $white;
	margin-bottom: 5px;
	margin-left: 5px;
	margin-right: 5px;

	&.twitter {

		//height: 200px;
		img.attachment {
			height: 50% !important;
			width: 50% !important;
			text-align: center;
		}
	}

	&.facebook,
	&.twitter {

		//height: 300px;
		img.attachment {
			height: 60% !important;
			width: 60% !important;
			text-align: center;
			margin-left: 6rem;
		}

	}

	overflow: hidden;

}

.social-feed-element a {
	color: $brand-dark !important;
}


#social-networks {
	button.select-social {
		background-color: transparent;
	}
}

.ytp-cued-thumbnail-overlay {
	width: 75% !important;

	@media(min-width: $screen-md-min) {
		width: 100%;
	}
}




//3.11 BULLETINS /newsletter

#newsletter {
	#mce-FNAME {
		width: 173px;
		border-radius: 3px;
		margin-right: 2.4rem;
		@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
			width: 373px;
		}
	}

	#mce-EMAIL {
		width: 327px;
		border-radius: 3px;
		margin-right: 2.4rem;
	}

	input[type="submit"],
	input[type="submit"]:hover,
	button:hover,
	input[type="submit"]:focus,
	button:focus {
		background-color: transparent !important;
		color: $white;
		border: none;

	}

}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
	display: block;
	width: 100%;
	height: 41px;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 27px;

	background-image: none;
	border: 1px solid #ccc;
	border-radius: 0;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.bg-header {
	background-image: linear-gradient(rgba(0, 124, 178, 0.67), rgba(0, 124, 178, 0.67)),
		url("++theme++ajpinedademar/img/overlay.png");
	background-repeat: no-repeat;
	background-size: cover;
	height: 300px;

	@media (min-width: $screen-sm-min) {
		height: 240px;
	}
}


// 4 - FOOTER

#custom-footer {
	#portal-social-links-footer {
		margin-left: 0;

		a:hover {
			background-color: $brand-primary;
		}

		ul {
			margin-left: 0.4rem !important; // separación entre los iconos

		}

		.rounded-icon {
			background-color: transparent !important;
			color: white !important;
			width: 35px;
			height: 35px;

			&:hover {
				background-color: $brand-primary !important;
			}
		}

		#footer-links {
			justify-content: center;
			margin-bottom: 3rem;

			@media (min-width: $screen-sm-min) {
				justify-content: flex-start;
			}
		}
	}
}

// 6 - GENERAL
#fondo-cabecera {
	background-color: $text-color;
}

#fondo_menu_slider_avisos {
	position: relative;
}

#interior-slider .interiorSlider__image {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: auto;

	img {
		object-fit: cover;
		object-position: center center;
		width: 100%;
		height: 100%;
	}
	&::after {
		position: absolute;
		top: 0;right: 0;bottom: 0;left: 0;background-color: $text-color;
		opacity: .45;
		content: '';
		display: block;
	}

	@media (min-width: $screen-md-min) {
	}
}

.search-wrapper {
	& > * {
		margin-bottom: 1.5rem;
	}
	.searchTextWrapper, .selectWrapper{
		flex: 1 0 100%;
	}
	.searchTextWrapper input {
		width: 100%;
	}
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		.searchTextWrapper, .selectWrapper{
			flex: 1 1 50%;
		}
		.searchTextWrapper{
			padding-right: .8rem;
		}
		.selectWrapper{
			padding-left: .8rem;
		}
	}
	@media (min-width: $screen-lg-min) { // 1200 pixels - 75em`
		& > * {
			margin-bottom: 0;
		}
		.searchTextWrapper, .selectWrapper{
			flex: 1 1 25%;
		}
	}
}

// 6.1 - SOCIAL - ICON


.documentActions {
	border-top: 2px solid $brand-text;

	div.col-md-8 li {
		width: 3.5rem;
		height: 3rem;
		margin-top: 2px;
	}

	div.col-md-8 .rounded-icon {
		background-color: $white;
		color: $brand-text;
		font-size: 16px;
		/*width: 100%;
		height: 100%;*/

		&:hover,
		&:focus {
			color: $white;
			border-radius: 50%;
			background-color: $brand-primary;

			span {
				color: $white;
			}

		}

		span:hover,
		span:focus {
			color: $white;
			text-decoration: none;

		}

	}

	.list-inline .col-md-4>li {
		display: inline-block;
		padding-left: 1px;
		padding-right: 1px;

	}


	a span {
		color: $brand-text;

		//background-color: $white;
		&:hover,
		&:focus {
			color: $brand-primary;
			text-decoration: underline;
		}
	}

}

//  6.4 - SIDEBAR / PORTLET NAVEGACIÓN

body#visual-portal-wrapper.pat-plone .outer-wrapper .portlet [class*="contenttype-"]:before {
	display: none;
}
#portlet-prefs ul ul li a:before {

	color: $brand-primary;
}

.contenttype-plone-site {
	display: none;
}


#sidebar {
	padding-left: 0px;
		.portlet {
			border: 0;
			box-shadow: none;

			.portletContent>ul>li a:before {
				content: "" !important;
				width: 0px;
				height: 0px;
			}

			img {
				display: none;
			}


		}
		.navTreeItem a img {
			display: none;
		}

		.portlet .portletContent {
			font-size: 16px;
			font-weight: $font-regular;
		}
		.navTreeItem.visualNoMarker.navTreeFolderish,
		.portletNavigationTree .portletContent>ul>li>a {

			background-color: transparent;
		}

		a.navTreeCurrentItem.navTreeCurrentNode {
			font-weight: $font-bold;
		}
		.portletNavigationTree .portletContent .navTreeCurrentNode>a {
			background: transparent;
			color: $brand-text_grey;
		}

		.portletContent,
		.portletNavigationTree {

			nav.portletContent>ul>li a {

				border-top: 0px !important;

			}

			border:0px !important;
		}

		.portletNavigationTree .portletContent {
			border: 0;
		}

		.portlet> :last-child {
			border-radius: 0;
		}


		li.navTreeCurrentNode a {
			&:before {
				content: none;
			}
		}

		.navTreeLevel0 ul {
			background-color: $brand-interes;
			padding-left: 2.4rem !important;

		}
		li.navTreeItemInPath a.navTreeItemInPath ul.navTree li.navTreeItem,
		li.navTreeItemInPath a.navTreeItemInPath ul.navTree li.navTreeItem a {
			padding-left: 6rem;
		}

		ul.navTree>li a ul li a {
			padding-left: 2.4rem;
		}
		.portlet .portletContent > ul li a{
			border-bottom: 1px solid transparent;
		}
		.portlet .portletContent > ul li a:hover,
		.portlet .portletContent > ul li a:focus {
			background-color: transparent;
			border-bottom: 1px solid $brand-primary;
		}
		.portlet .portletContent > ul li:hover,
		.portlet .portletContent > ul li:focus {
			background-color: transparent;
		}

		.portletNavigationTree .portletContent>ul>li>a:hover:after {
			content: "\e807";
			position: absolute;
			right: 10px;
			margin-top: -3px;
			font-family: pictello;
		}

		.portletNavigationTree .portletContent>ul>li.navTreeFolderish>a:after {

			content: "\e805";
			position: absolute;
			right: 10px;
			margin-top: -3px;
			font-family: pictello;

		}

		.portletNavigationTree .portletContent .navTreeCurrentNode>a:after {
			content: "\e806" !important;
			position: absolute;
			right: 10px;
			color: $brand_dark;
			font-family: pictello;
			padding-left: 1px;
		}

		.portletNavigationTree .portletContent>ul>li>ul a {
			padding-left: 20px;

		}

		ul.navTree.navTreeLevel2,
		ul.navTree.navTreeLevel3,
		ul.navTree.navTreeLevel4,
		ul.navTree.navTreeLevel5 {
			padding-left: 20px !important;
		}

	}

	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		#sidebar, .stickyBar {
			position: sticky;
			top: 8.0rem;
		}
	}



// 6.5 Breadcrums / Camino de migas

.plone-breadcrumb a,
#breadcrumbs-current {
	color: $brand-text_grey
}

.hiddenStructure {
	display: none !important;
}
#breadcrumbs-current {font-weight: $font-bold;}



//6.6 Display Flex


.displayFlex {
	display: flex;

	&.row,
	&.clearfix {

		&::before,
		&::after {
			content: none;
		}
	}
}

.flexEnd {
	align-self: flex-end;
}

.flexStrech {
	align-items: stretch;
}

.flexDirectionColumn {
	flex-direction: column;
}

.flexDirectionRow {
	flex-direction: row;
}

.flex1 {
	flex: 1;
}

.flexAlignSelfEnd {
	align-self: flex-end;
}

.justifySpace-between {
	justify-content: space-between;
}

.justifyEnd {
	justify-content: flex-end;
}

.justifyCenter {
	justify-content: center;
}

.flexWrapWrap {
	flex-wrap: wrap;
}

.justifyStart {
	justify-content: flex-start;
}

//6.7 PÁGINA



.summary,
.documentDescription.description {

	font-family: Poppins;
	font-weight: normal;
	font-size: 20px;
	color: $brand-text;

}


#galleryImages {

	img,
	.imageGallery__item {
		height: 178px;
		width: auto;
		@supports (object-fit: cover) {
			width: 100%;
		}
	}
}

//6-8 PAGINACIÓN / pagination

.pagination ul>li>a,
.pagination ul>li>span {
	border: 0px;
}

.pagination ul>.active>a,
.pagination ul>.active>a:hover,
.pagination ul>.active>a:focus,
.pagination ul>.active>span,
.pagination ul>.active>span:hover,
.pagination ul>.active>span:focus {

	color: $brand-dark;
	background-color: transparent;
	border-color: transparent;
	font-size: 19px !important;

}

.pagination ul>li>a:hover,
.pagination ul>li>a:focus,
.pagination ul>li>span:hover,
.pagination ul>li>span:focus {
	z-index: 2;
	color: $brand-dark;
	background-color: transparent;
	border-color: transparent;
}

.pagination ul>li>a,
.pagination ul>li>span {
	font-size: 19px !important;
	color: $brand-primary;
}

//6.9 SITEMAP

#portal-sitemap {
	a {
		color: $text-color;
	}

	ul li {
		margin-bottom: .5em;
		ul {
			font-size: .9em;
		}
	}
}

// 6.10 scrollup

#scrollup {
	display: block;
	width: 4.0rem;
	height: 4.0rem;
	position: fixed;
	bottom: 1.5rem;
	right: 1.5rem;
	padding: 5px;
	cursor: pointer;
	text-align: center;
	background-color: $brand-primary;
	border-radius: 50%;
	color: $white;
	z-index: 999;
	border: 0.2rem solid $brand-dark;
	opacity: 0;
	transition: opacity 0.35s ease;
	font-size: 1.6rem;
}

#scrollup.showScroll {
	opacity: 1;
}


// 6.11 PLANO

.panel-collapse.collapse.in {


	div.panel-heading a {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 3rem;
	}
}

article.infoCard img {
	height: 200px;
}

// 7 - MINISITE


.minisite_events {

	.events-item-image {
		height: 180px;

	}

	.eventsItem__date {
		position: absolute;
		top: 0;
		left: 12px;
	}

	div.reloadContent__child {
		flex-direction: column;

		@media (min-width: $screen-sm-min) {
			flex-direction: row;
		}
	}


}

.minisite_news {
	.newsItem__image {
		height: 180px;
	}

}
.minisite__title {
	font-size: 2.0rem;
	@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
		font-size: 3.8rem;
	}
}
.interiorSlider__image,
.interiorSlider__image img {

	height: 120px;

	@media (min-width: $screen-sm-min) {
		height: 300px;
	}

	width: 100%;
	object-fit:cover;


}

#minisite-slider {

	.portalSlider__image,
	.portalSlider__image img {
		height: 331px;
		width: 100%;
		object-fit: cover;

		@media (min-width: $screen-sm-min) {
			width: 554px;
			max-width: 100%;
		}
	}

	.portalSlider__header {
		padding-right: 1.5rem;
		border-right: 2px solid $white;
	}

	.owl-dots {
		display: none;

		@media (min-width: $screen-sm-min) {
			display: block;
			background-color: transparent;
			position: absolute;
			bottom: 0px;
			right: 25rem;

			button:focus {
				outline: none;
			}
		}

	}

	.portalSlider__text {
		background: transparent;
		height: auto;
	}

	//minisite slider owl
	.owl-dots {
		margin-bottom: 1.6rem;
	}

	button.owl-dot span {
		color: transparent;
	}

	button.owl-dot:after {
		content: '';
		background-color: white;
		width: 12px;
		height: 12px;
		display: inline-block;
		border-radius: 6px;
	}

	button.owl-dot.active:after {
		background-color: $brand-primary;

	}
}

.get-more-news-minisite,
.get-more-events-minisite {
	font-family: Poppins;
	font-weight: bold;
	text-align: center;
	border: 2px solid $gray-dark;
	border-radius: 4px;
	transition: background-color 0.35s ease-out, color 0.35s ease-out;

	&:hover,
	&:focus {
		border: 2px solid $brand-dark;
		background-color: $brand-primary;
		color: $white !important;
	}

}

#minisite-info {

	font-weight: $font-light;
	color: $white;

	&.bg-header {
		background: rgba(0, 124, 168, 0.8);
		height: 100%;
		a {
			color: $white;
			border-bottom: 1px solid $white;
			&:hover, &:focus {
				color: $text-color;
			}
		}
		nav a {
			text-decoration: none !important;
			border-bottom: none;
		}
	}

	.minisite-info__description {
		line-height: 1.3;
	}

	.minisite-info__text {
		font-weight: $font-regular;

		p {
			margin-bottom: 5px;
		}
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		.minisite-info__row {
			display: flex;
			&::before, &::after {
				content: none;
			}
			nav > *{
				height: 100%;

			}
		}
	}
}

.minisitePortadaNews {
	padding-left: -30px;
	padding-right: -30px;
}

.portaltype-minisite .event__item {
	transition: color $transition-fast ease-in;

	&:hover {
		background-color: $brand-primary;
		opacity: 0.9;

		a,
		p,
		span {
			color: $white;
		}
	}
}






//8 - EDITOR
#btn-selected-items {
	span.label.label-default {
		color: $white;
	}
}

.autotoc-nav,
.pattern-relateditems.contenttype-image {
	a {
		color: $brand-dark;
	}
}

#btn-selected-items .label {
	color: $white;
}




//9 - DIRECTORY

.guidePanel__accordion {
	padding-right: 2rem;
}

#directory-map {
	@media (min-width: $screen-sm-min) {
		padding-left: 3rem;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}
}

.guidePanel {


	a.accordion-link {
		background-color: transparent;

		&:after {
			content: '\e80D'; //abajo
			font-family: "pictello";
			font-style: normal;
		}
	}
}

#accordion a:hover {
	background-color: transparent;
}

#location-map {
	margin-top: 6rem;margin-bottom: 6rem;
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		margin-top: 0;
	}
}

.filter-close .picto-cancel {
	a {
		color: $brand-primary;
	}
}

.panel-collapse .panel-heading {
	padding-left: 2rem;

	a {
		color: $text-color;
	}
}

div.panel {
	a[aria-expanded="true"] {
		content: "prueba";

		.picto-right-open-1:before {
			content: '\e811';
			font-family: "pictello";
			font-style: normal;
		}
	}
}

.panel-body div.panel-group div.panel {
	a[aria-expanded="false"] {
		.picto-right-open-1:before {
			content: '\f0fe';
			font-family: "pictello";
			font-style: normal;
		}
	}
}

.panel-body div.panel-group div.panel {
	a[aria-expanded="true"] {
		.picto-right-open-1:before {
			content: '\f146';
			font-family: "pictello";
			font-style: normal;
		}
	}
}

.filter-box {
	padding: 0.5rem 1rem;
	font-family: $font-family-serif;
}

// FLEX
.displayFlex {
	display: flex;
}

.flexEnd {
	align-self: flex-end;
}

.flexStrech {
	align-items: stretch;
}

.flexDirectionColumn {
	flex-direction: column;
}

.flexDirectionRow {
	flex-direction: row;
}

.flex1 {
	flex: 1;
}

.flexAlignSelfEnd {
	align-self: flex-end;
}

.justifySpace-between {
	justify-content: space-between;
}

.justifyEnd {
	justify-content: flex-end;
}

.justifyCenter {
	justify-content: center;
}

.flexWrapWrap {
	flex-wrap: wrap;
}

.justifyStart {
	justify-content: flex-start;
}


// #######################################
// 10 - VIEWS

// 10.1 - Unfolded blog
.blogUnfolded {
	&__title {
		position: relative;
		background-size: cover;
		background-color: $brand-primary;
		&:before {
			content: '';
			display: block;
			opacity: .75;
			position: absolute;
			top: 0;right: 0;bottom: 0;left: 0;
			z-index: 0;
			background-color: $brand-primary;
			transition: all $transition-fast ease-in;
		}
		&:hover, &:focus {
			&::before {
				opacity: .9;
				background-color: $brand-dark;
			}
		}
		a {
			color: $white;
			&:hover, &:focus {
				color: $white;
				text-decoration: none !important;
			}
		}
	}
	&__subChilds {
		display: flex;
		flex-wrap: wrap;
		@supports (display: flex) or (display: -webkit-box) or (display: -ms-flexbox) {
			&:after, &:before {
				display: none;
				content: none;
			}
		}
		&__link {
			background-color: transparent;
			border: .1rem solid $brand-gray;
			color: $text-color;
			transition: all $transition-fast ease-in;
			display: flex;
			align-items: center;
			height: 100%;
			position: relative;
			overflow: hidden;
			&::before {
				content: '';
				position: absolute;
				top: 0;right: 0;bottom: 0;left: 0;
				transform: translateX(-100.5%);
				transition: transform $transition-fast ease-in;
				background-color: $brand-primary;
				z-index: $zindex-negative;
			}
			&__icon {
				color: $brand-primary;
			}
			&:focus, &:hover {
				color: $white;
				transform:  scale(1.05);
				text-decoration: none !important;
				span {
					color: $white;
				}
				&::before {
					transform: translateX(0);
				}
			}
		}
	}
}
.js .tooLong {
	max-height: 14.5rem;
	overflow: hidden;
	transition: max-height $transition-slow ease-in-out;
	position: relative;
	&__btn {
		position: absolute;
		bottom: 0;
		z-index: 1;
		background-color: $white;
		width: 100%;
		box-shadow: 0 -1px 5px rgba($gray-base,.26);
	}
	&.enlarged {
		max-height: 120.0rem;
	}
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		max-height: none !important;
		&__btn {
			display: none;
		}
	}
}

.template-blog_unfolded_view{

	.subchilds-wrapper{

		.subchild-title-wrapper{
			display:flex;

			.subchild-title{
				flex: 1;
			}
		}
	}
}

// 10.2 Search news

.search-wrapper-news{
	align-items: center !important;
    border-radius: 5px;
}

.newsItem {

	position: relative;
	border: .1rem solid $gray-lighter;
	// background-color: $white;
	&__image {
		height: 20.0rem;
		overflow: hidden;
	}
	&__link {
		transition: all $transition-fast ease-in;
		color: $text-color;
		&:hover,
		&:focus {
			background-color: $brand-primary;
			color: $white;
			height: calc(100% + 4.8rem);
			text-decoration: none !important;
			&::after {
				background-color: transparent;
				box-shadow: none;
				color: white;
			}
		}
		&.eventItem__link:hover, &.eventItem__link:focus {
			height: auto;
		}
	}
	&__footer {
		position: absolute;
		left: 0; right: 0; bottom: 1.8rem;
		* {
			white-space: nowrap;
		}
	}
	&--featured {
		.newsItem__link {
			&::after {
				left: 50%;
				transform: translate(-50%, -50%);
				right: auto;
			}
		}
	}
	&--noImage {
		.newsItem__link, .newsItem__text { height: 100%;}
		.newsItem__link {
			min-height: 26.5rem;
			&:hover,
			&:focus {
				height: calc(100% + 4.8rem);
			}
		}
	}
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		&--featured {
			min-height: 33rem;

			.newsItem__image {
				min-height: 33rem;
				height: auto;
				a::after {
					top: 33rem / 2;
				}
			}
		}
	}
	@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
		&--featured .newsItem__image {
			height: 33rem;
		}
	}
}
.defaultImage {
	width: 100%; height: auto;
	background-color: $brand-primary;
	@supports (object-fit: contain) {
		object-fit: contain;
		height: 100%;
	}
}

.searchLoader {
	position: absolute;
	top: 0;right: 0;bottom: 0;left: 0;
	background-color: $white;
	z-index: $zindex-flat + 1;
}

// 10.3 Search events

.search-wrapper-events{
	align-items: center !important;
    border-radius: 5px;
}

.eventItem {
	&__day {
		background-color: $gray-lighter;
		color: $gray-darker;
		font-family: $headings-font-family;
		text-align: center;
		font-weight: $font-semi;
		height: 5.7rem; width: 6.0rem;
		padding-top: .3rem;
		line-height: 1;
		position: absolute;
		left: 1.5rem; top: 0;
		z-index: $zindex-flat;
		& > * {
			display: block;
		}
		abbr {
			border-bottom: none;
			text-decoration: none;
			cursor: default;
		}
		&__day {
			font-size: 3.2rem;
		}
		&__month {
			text-transform: uppercase;
			font-size: 1.8rem;
		}
	}
	&__title {
		font-size: 2.0rem;
		line-height: 1.25;
		min-height: 2.5em;
	}
	.eventItem__link {
		&::after {
			right: 50%;
			transform: translate(50% , -50%);
		}

	}
}

// 10.4 - template-accordion_view
.template-accordion_view {
	.panel-default > .panel-heading {
		background-color: $brand-primary;
		border-color: $brand-primary;
		padding-top: 25px;
		padding-bottom: 25px;
		* {
			color: $white !important;
		}
		.arrow {
			margin-top: 2.4rem !important;
		}
	  }	
}


// 10.5 - custom emplate-event_view
.template-event_view {
	#location-map {
		height: auto !important;
		position: relative;
		aspect-ratio: 3 / 2;
		outline: none;
	}
}